import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPrograms } from "../../redux/programActions";
import '../pages.scss';
import { IoMdArrowDropright } from "react-icons/io";
import Image from 'react-bootstrap/Image';
import { IoIosSearch } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { useLocation } from 'react-router-dom';


function ProgramPage() {
    const location = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { principal_name, principal_id } = location.state || {};
    const { program, loading, error } = useSelector((state) => state.program);

    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setSearchVisible] = useState(false); // State to manage search visibility

    useEffect(() => {
        dispatch(fetchPrograms(id));
    }, [dispatch, id]);

    const handleClick = async (programId, name) => {
        try {
            navigate('/program/' + programId, {
                state:{
                    program_name: name, 
                    program_id: programId,
                    principal_name,
                    principal_id,
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredPrograms = program ? program["rows"].filter(item =>
        item["programName"].toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const toggleSearchVisibility = () => {
        setSearchVisible(prev => !prev);
        if (isSearchVisible) {
            setSearchTerm(""); // Clear search term when hiding
        }
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {program && (
                <div>
                    <div className="navbar-header">
                        <IoMdArrowBack onClick={() => navigate("/principal")} />

                        <div>
                            {!isSearchVisible && (<>Programs
                                <div className="subheading">{principal_name}</div></>
                        )}
                           
                        </div>
                        <div className="navbar-search">
                            <input
                                className={isSearchVisible ? "active" : ""}
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search"
                            />
                            <IoIosSearch
                                onClick={toggleSearchVisibility} // Toggle search visibility on icon click
                            />
                        </div>

                    </div>
                    <div className="navbar-page">
                        {filteredPrograms.map((item, index) => (
                            <div key={index} className="list-item" onClick={() => handleClick(item["programId"], item["displayName"])}>
                                <div>
                                    {item["logo"] ? <Image src={item["logo"]} alt={item["programName"]} /> : null}
                                    <div>{item["programName"]}</div>
                                </div>
                                <IoMdArrowDropright />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProgramPage;
