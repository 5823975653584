import axios from 'axios';

export const FETCH_PROGRAM_REQUEST = 'FETCH_PROGRAM_REQUEST';
export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';
export const FETCH_PROGRAM_FAILURE = 'FETCH_PROGRAM_FAILURE';

export const CLEAR_STORE = "CLEAR_STORE"

export const clearStoreAction = () => ({
    type: 'CLEAR_STORE',
});

export const fetchPrograms = (principalId) => async (dispatch) => {
    dispatch({ type: FETCH_PROGRAM_REQUEST });
    try {
        const token = localStorage.getItem('accessToken')


        const response = await axios.request({
            baseURL: 'https://apitest.fincentive.co' + `/v1/programs/programs/?principalId=${principalId}`, // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            method: 'GET'
        })

        dispatch({ type: FETCH_PROGRAM_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_PROGRAM_FAILURE, error: error.message });
    }
};

