import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTenant } from "../../redux/tenantActions";
import '../pages.scss'
import { IoMdArrowBack, IoMdArrowDropright } from "react-icons/io";
import { useParams } from 'react-router-dom';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import PlaceholderImage from "../../assests/placeholder.jpg";




function TenantDetailPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { program_name, program_id, principal_name, principal_id, tenant_id, tenant_name } = location.state || {};


    const { tenant_details, loading, error } = useSelector((state) => state.tenant);


    useEffect(() => {
        dispatch(fetchTenant(id))
    }, []);

    const handleClick = async () => {
        try {
            navigate('/tenant/edit/' + id, {
                state: {
                    program_name,
                    program_id,
                    principal_name,
                    principal_id,
                    tenant_id,
                    tenant_name
                }
            })
        } catch (error) {
        }
    };


    const handleVpaClick = (tenandId) => {
        try {
            navigate(`/tenant/vpa/${tenandId}`, {
                state: {
                    program_name,
                    program_id,
                    principal_name,
                    principal_id,
                    tenant_id,
                    tenant_name
                }
            })
        } catch (error) {
        }
    };



    return (
        <div>
            {loading && (
                <div className="loading-overlay" >
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {
                tenant_details && (
                    <div>

                        <div className='tenant-navbar-header'>
                            <IoMdArrowBack onClick={() => navigate("/program/" + program_id, {
                                state: {
                                    program_name,
                                    program_id,
                                    principal_name,
                                    principal_id,
                                    tenant_id,
                                    tenant_name
                                }
                            })} />
                            {tenant_name}
                            <Button onClick={handleClick}>Edit</Button>
                        </div>


                        <div className="tenant-page">
                            <Image src={tenant_details["storeImage"] ? tenant_details["storeImage"] + `?t=${new Date().getTime()}` : PlaceholderImage} className="tenant" />

                            <div className="add-vpa-section">
                                {tenant_details["vpa_count"]} VPAs
                                <Button onClick={() => handleVpaClick(tenant_details["tenantId"])}>Add VPA</Button>
                            </div>

                            <div className="title">
                                Store Id: {tenant_details["tenantId"]}

                            </div>


                            <div className="tenant-contact-details">


                                <Form >
                                    <div className="disable">
                                        <FloatingLabel className="disabled-label" controlId="name" label="Contact Person Name">
                                            <Form.Control disabled className="disabled" as="textarea" placeholder="Name" value={tenant_details["storeCoordinator"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">

                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="number" label="Contact Person Number">
                                            <Form.Control disabled className="disabled" as="textarea" placeholder="Phone Number" value={tenant_details["phone"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="address" label="Address">
                                            <Form.Control disabled className="disabled" as="textarea" placeholder="Address Line 1" value={tenant_details["address"]} />
                                        </FloatingLabel>
                                    </div>

                                    <div className="disable">

                                        <FloatingLabel controlId="state" label="State">
                                            <Form.Control disabled className="disabled" as="textarea" placeholder="State" value={tenant_details["state"]} />
                                        </FloatingLabel>
                                    </div>


                                    <div className="disable">

                                        <FloatingLabel controlId="city" label="City">
                                            <Form.Control disabled className="disabled" as="textarea" placeholder="City" value={tenant_details["city"]} />
                                        </FloatingLabel>
                                    </div>







                                </Form>
                            </div>

                        </div>

                    </div>





                )}





        </div>
    );
}

export default TenantDetailPage;
