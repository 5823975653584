import React, { useState, useEffect } from 'react';
import './LoginPage.scss';  // Import the stylesheet
import logo from '../../assests/loginlogo.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';

const LoginPage = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);  // State for loading indicator
  const [error, setError] = useState('');  // State for error message
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {

    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });

    // Reset error for the changed field
    setFormErrors({
      ...formErrors,
      [id]: ''
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.email & !validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email.'
    }
    if (!formData.password) {
      errors.password = 'Please enter a valid password.'
    }
    return errors;
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }


    setLoading(true);  // Show loader
    setFormErrors({});  // Clear any previous errors
    setError(null)

    try {
      const response = await axios.post('https://apitest.fincentive.co/api/v1/auth/login', formData);

      if (response.status === 200) {
        const { user, tokens } = response.data;

        localStorage.setItem('accessToken', tokens.access.token);
        localStorage.setItem('refreshToken', tokens.refresh.token);
        localStorage.setItem('userDetails', JSON.stringify(user));
        setAuth(true);

        navigate('/principal');
      } else {
        console.log("wkjdkbkb", response.status)
        setError('Login failed. Please try again.');
      }
    } catch (error) {
      console.log("wkjnmdkbkb", error)
      setError('Login error: ' + (error.response ? error.response.data.message : error.message));
      setFormData({
        email: '',
        password: '',
      })
    } finally {
      setLoading(false);  // Hide loader
    }
  };
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');  // Clear error after 3 seconds
      }, 2000);  // 3 seconds

      // Cleanup timeout if the component unmounts or error changes
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className="login-page">
      <img
        src={logo}
        alt="Fincentive Logo"
        className="logo"
      />
      <div className="login-container">
        {!loading && (<h2 >Login</h2>)}

        {/* Show loading spinner in the center */}
        {loading && (
          <div className="loading-spinner">
            <div className="spinner"></div> {/* Add CSS for the spinner */}
          </div>
        )}

        {!loading && (
          <form onSubmit={handleLogin}>
            <Form.Group controlId="email" className="input-group">
              <Form.Label className="border-label">Email ID</Form.Label>
              <Form.Control
                type="text"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email ID"
                isInvalid={formErrors.email}
              />
              {formErrors.email && (
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="password" className="input-group">
              <Form.Label className="border-label">Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                isInvalid={formErrors.password}
              />
              {formErrors.password && (
                <Form.Control.Feedback type="invalid">
                  {formErrors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <button type="submit" className="login-button">Login</button>
          </form>
        )}

        {/* Show error message as bottom popup */}
        {error && (
          <div className="error-popup">
            Invalid Credentials
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
