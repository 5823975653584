import {
    FETCH_TENANT_REQUEST,
    FETCH_TENANT_SUCCESS,
    FETCH_TENANT_FAILURE,
    FETCH_TENANT_DETAIL_REQUEST,
    FETCH_TENANT_DETAIL_SUCCESS,
    FETCH_TENANT_DETAIL_FAILURE,
    CLEAR_STORE,
    UPDATE_TENANT_DETAIL_REQUEST,
    UPDATE_TENANT_DETAIL_SUCCESS,
    UPDATE_TENANT_DETAIL_FAILURE
} from './tenantActions';

const initialState = {
    loading: true,
    tenant: null,
    error: null,
    tenant_details: null
};

const tenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TENANT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_TENANT_SUCCESS:
            return {
                ...state,
                loading: false,
                tenant: action.payload,
            };
        case FETCH_TENANT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case FETCH_TENANT_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_TENANT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                tenant_details: action.payload,
            };
        case FETCH_TENANT_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
            case UPDATE_TENANT_DETAIL_REQUEST:
                return {
                    ...state,
                    loading: true,
                    error: null,
                };
            case UPDATE_TENANT_DETAIL_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    tenant_details: action.payload,
                };
            case UPDATE_TENANT_DETAIL_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.error,
                };
        case CLEAR_STORE:
            return initialState; // Reset the store to the initial state
        default:
            return state;
    }
};

export default tenantReducer;