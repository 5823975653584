import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Spinner, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTenant, updateTenant } from "../../redux/tenantActions";
import '../pages.scss'
import { useParams } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import PlaceholderImage from "../../assests/placeholder.jpg";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { MdOutlineFileUpload } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';


function TenantEditPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { tenant_details, loading, error } = useSelector((state) => state.tenant);
    const [formData, setFormData] = useState({
        storeCoordinator: '',
        phone: '',
        address: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [image, setImage] = useState(PlaceholderImage); // State for the uploaded image
    const [file, setFile] = useState(null);
    const location = useLocation();
    const { program_name, program_id, principal_name, principal_id, tenant_id, tenant_name } = location.state || {};
    const [latitude, setLatitude] = useState(null); // Latitude of the captured location
    const [longitude, setLongitude] = useState(null); // Longitude of the captured location
    const [capturedFile, setCapturedFile] = useState(null); // State to store the captured image file
    const [locationError, setLocationError] = useState(false);
    const [cameraError, setCameraError] = useState(false);
    const [showModal, setShowModal] = useState(false); // State for controlling modal visibility
    const webcamRef = useRef(null);



    // Open the webcam modal
    const openCameraModal = () => {
        setShowModal(true);
    };

    // Close the webcam modal
    const closeCameraModal = () => {
        setShowModal(false);
    };

    const requestLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);
                setLocationError(false);
            },
            (error) => {
                console.error("Error getting location: ", error);
                setLocationError(true);
            }
        );
    };

    const requestCameraPermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraError(false);
        } catch (err) {
            console.error('Error accessing camera: ', err);
            setCameraError(true);
        }
    };

    // useEffect to request camera and location permissions on component mount
    useEffect(() => {
        const askPermissions = async () => {
            try {
                // Camera and location permissions run in parallel
                await Promise.all([requestCameraPermission(), requestLocationPermission()]);
            } catch (err) {
                console.error("Error asking for permissions: ", err);
            }
        };

        askPermissions();
    }, []);



    const capture = async () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImage(imageSrc); // Update the image with the captured one

            // Convert the base64 image to a file (Blob)
            const blob = await fetch(imageSrc).then((res) => res.blob());
            setCapturedFile(new File([blob], 'captured_image.jpg', { type: blob.type }));

            // Get user's location (latitude and longitude)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLatitude(latitude);
                        setLongitude(longitude);
                    },
                    (error) => {
                        console.error("Error getting location: ", error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
            }

            setShowModal(false); // Close modal after capturing
        }
    };


    const saveTenant = async () => {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        try {
            await dispatch(updateTenant(formData.storeCoordinator, formData.phone, formData.address, capturedFile, id, latitude, longitude)); // Wait for the promise to resolve
            navigate('/tenant/' + id, {
                state: {
                    program_name,
                    program_id,
                    principal_name,
                    principal_id,
                    tenant_id,
                    tenant_name
                }
            })

        } catch (error) {
            console.error(error);
        }
    };



    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Set the uploaded image
            };
            reader.readAsDataURL(file);
            setFile(file);
        }
    };

    useEffect(() => {
        dispatch(fetchTenant(id))
    }, []);

    useEffect(() => {
        if (tenant_details) {
            setFormData({
                storeCoordinator: tenant_details.storeCoordinator || '',
                phone: tenant_details.phone || '',
                address: tenant_details.address || '',
            });
            if (tenant_details.storeImage) {
                setImage(tenant_details.storeImage + `?t=${new Date().getTime()}`);
            }
        }
    }, [tenant_details]);

    const handleChange = (e) => {

        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });

        // Reset error for the changed field
        setFormErrors({
            ...formErrors,
            [id]: ''
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.storeCoordinator) {
            errors.storeCoordinator = "Contact Person Name is required.";
        }
        if (!formData.phone) {
            errors.phone = "Contact Person Number is required.";
        } else if (!/^(?:\+91|91|0)?[789]\d{9}$/.test(formData.phone)) {
            errors.phone = "Phone number must be numeric with 10 digits.";
        }
        if (!formData.address) {
            errors.address = "Address is required.";
        }
        return errors;
    };

    const modal_content = () => {

        if (cameraError && locationError) {
            return (<Modal.Body><div>
                <p>Camera and location access denied. Please allow camera and location access to continue.</p>
            </div></Modal.Body>)
        }

        else if (cameraError) {
            return (<Modal.Body><div>
                <p>Camera access denied. Please allow camera access to continue.</p>
            </div></Modal.Body>)

        }
        else if (locationError) {
            return (<Modal.Body><div>
                <p>Location access denied. Please allow location access to continue.</p>
            </div></Modal.Body>)
        }
        else {
            return (
                <>
                    <Modal.Body><Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{ facingMode: "environment"  }}
                        width="100%"
                    />
                    </Modal.Body>
                    <Modal.Footer className="footer" >
                        <button onClick={capture}>Capture</button>
                    </Modal.Footer>
                </>
            )
        }
    }



    return (
        <div>
            {loading && (
                <div className="loading-overlay" >
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {
                tenant_details && (
                    <div>

                        <div className='tenant-navbar-header'>
                            <IoMdArrowBack onClick={() => navigate("/tenant/" + id, {
                                state: {
                                    program_name,
                                    program_id,
                                    principal_name,
                                    principal_id,
                                    tenant_id,
                                    tenant_name
                                }
                            })} />
                            Update {tenant_name}
                            <Button onClick={saveTenant}>Save</Button>
                        </div>


                        <div className="tenant-page">

                            <div className="image-container">
                                <Image src={image} className="tenant" />

                                <div onClick={openCameraModal} htmlFor="imageUpload" className="overlay">
                                    <MdOutlineFileUpload />

                                </div >

                                <Modal className="popup" show={showModal} onHide={closeCameraModal} centered>
                                    <Modal.Header>
                                        <Modal.Title>Capture Image</Modal.Title>
                                        <Button onClick={closeCameraModal}>✖</Button>
                                    </Modal.Header>
                                    {modal_content()}
                                </Modal>
                            </div>


                            <div className="title">
                                Store ID: {tenant_details["tenantId"]}

                            </div>


                            <div className="tenant-contact-details">


                                <Form>
                                    <div className="enable">
                                        <FloatingLabel controlId="storeCoordinator" label="Contact Person Name">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Name"
                                                value={formData.storeCoordinator}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.storeCoordinator}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formErrors.storeCoordinator}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>

                                    </div>

                                    <div className="enable">
                                        <FloatingLabel controlId="phone" label="Contact Person Number">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Phone Number"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.phone}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formErrors.phone}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>

                                    <div className="enable">
                                        <FloatingLabel controlId="address" label="Address">
                                            <Form.Control
                                                placeholder="Address Line 1"
                                                value={formData.address}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.address}
                                            />
                                        </FloatingLabel>

                                    </div>

                                    <div className="disable">
                                        <FloatingLabel controlId="state" label="State">
                                            <Form.Control as="textarea" placeholder="State" value={tenant_details["state"]} />
                                        </FloatingLabel>

                                    </div>
                                    <div className="disable">
                                        <FloatingLabel controlId="city" label="City">
                                            <Form.Control as="textarea" placeholder="City" value={tenant_details["city"]} />
                                        </FloatingLabel>
                                    </div>



                                </Form>
                            </div>

                        </div>

                    </div>





                )}





        </div>
    );
}

export default TenantEditPage;
