import {
    FETCH_PRINCIPAL_REQUEST,
    FETCH_PRINCIPAL_SUCCESS,
    FETCH_PRINCIPAL_FAILURE,
    CLEAR_STORE
} from './principalActions';

const initialState = {
    loading: true,
    principal: null,
    error: null
};

const voucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRINCIPAL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_PRINCIPAL_SUCCESS:
            return {
                ...state,
                loading: false,
                principal: action.payload,
            };
        case FETCH_PRINCIPAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CLEAR_STORE:
            return initialState; // Reset the store to the initial state
        default:
            return state;
    }
};

export default voucherReducer;