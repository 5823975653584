import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import jsQR from 'jsqr'; // Add jsQR to decode the QR code from image data
import { useDispatch } from 'react-redux'; // Import useDispatch
import { addVPA } from '../../redux/vpaActions';
import { useNavigate, useParams } from 'react-router-dom';
import '../pages.scss';
import { Button, Image } from 'react-bootstrap';
import { IoIosSearch } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import { useLocation } from 'react-router-dom';


const QRScanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [isScanning, setIsScanning] = useState(true);
  const [qrData, setQrData] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // Add state for error popup
  const location = useLocation();
  const { program_name, program_id, principal_name, principal_id, tenant_id, tenant_name } = location.state || {};

  const dispatch = useDispatch(); // Initialize useDispatch

  const handleError = (err) => {
    console.error('QR Code Error:', err);
    setShowErrorPopup(true); // Show error popup when an error occurs
    setTimeout(() => {
      setShowErrorPopup(false);
      window.location.reload(); // Reload the page after 2 seconds
    }, 2000);
  };

  const handleReset = (e) => {
    setIsScanning(true)
    setQrData(null)
    setImage(null)
  }

  function extractUpiDetails(upiLink) {
    const url = new URL(upiLink);
    const vpa = url.searchParams.get('pa'); // Extract the virtual payment address
    const merchantName = url.searchParams.get('pn') || ""; // Extract the merchant name
    const providerName = vpa ? vpa.split('@')[1] : null;

    return { vpa, providerName, merchantName };
  }

  const captureImage = async () => {
    const videoElement = document.querySelector('video');

    if (videoElement) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      context.drawImage(videoElement, 0, 0);
      const imgData = canvas.toDataURL('image/png'); // Store the captured image
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const qrCode = jsQR(imageData.data, canvas.width, canvas.height);

      if (qrCode) {
        console.log('QR Code Data:', qrCode.data);
        setQrData(qrCode.data);
        setImage(imgData);
        setIsScanning(false);
      }
    }
  };

  const handleScan = async () => {
    //setLoading(true); // Show loading spinner
    try {
      const result = extractUpiDetails(qrData);
      console.log("Valid UPI Link:", result.vpa);
      await saveUrlAndQr(image, {
        vpa: result.vpa,
        providerName: result.providerName,
        merchantName: result.merchantName,
        status: "ACTIVE",
      });



    } catch (err) {
      console.log("error", err)
      // handleError(err);
    } finally {
      //setLoading(false); // Hide loading spinner
    }
  };

  const saveUrlAndQr = async (imageData, body) => {
    const response = await fetch(imageData);
    const blob = await response.blob();
    await dispatch(addVPA(blob, body.vpa, body.providerName, body.status, body.merchantName, id));
    navigate(`/tenant/vpa/${tenant_id}`,
      {
        state: {
          program_name,
          program_id,
          principal_name,
          principal_id,
          tenant_id,
          tenant_name
        }
      }
    )
  };

  const handleCancel = () => {
    window.location.reload(); // Refresh the page to restart scanning
  };

  // useEffect(() => {
  //   if (loading) {
  //     const timer = setTimeout(() => {
  //       setLoading(false);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     }, 60000); // 1 minute timeout

  //     return () => clearTimeout(timer);
  //   }
  // }, [loading]);

  return (
    <div>
      <div className="tenant-navbar-header">
        <IoMdArrowBack onClick={() => navigate("/tenant/vpa/" + tenant_id, {
          state: {
            program_name,
            program_id,
            principal_name,
            principal_id,
            tenant_id,
            tenant_name
          }
        })} />
        <div>Scan QR <div className="subheading">{tenant_name}</div></div>
        <Button onClick={handleReset}>Reset</Button>

      </div>
      <div className="qr-page">


        <div className='scanner'>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result, error) => {
              if (!!result) {
                captureImage(); // Capture image on first scan
              }
            }}
          />
        </div>

        {!isScanning && (<div className="list-card">
          <div className="list-card-img">
            <Image src={image} />
          </div>
          <div className="card-body">
            <div className="item">{extractUpiDetails(qrData).vpa}</div>
            <div >
              <Button
                onClick={handleScan}
              > Add
              </Button>

            </div>
          </div>
        </div>)}


        {/* {loading && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <div>Loading...</div>
        </div>
      )} */}

        {/* Error Popup */}
        {/* {showErrorPopup && (
        <div className="error-popup" style={popupStyles}>
          Error occurred
        </div>
      )} */}
      </div>
    </div>
  );
};

const popupStyles = {
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#f44336',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  fontSize: '16px',
  zIndex: 1000,
};

export default QRScanner;
