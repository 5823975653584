import {
    FETCH_VPA_REQUEST,
    FETCH_VPA_SUCCESS,
    FETCH_VPA_FAILURE,
    CLEAR_STORE,
    UPDATE_VPA_DETAIL_REQUEST,
    UPDATE_VPA_DETAIL_SUCCESS,
    UPDATE_VPA_DETAIL_FAILURE,
    DELETE_VPA_REQUEST,
    DELETE_VPA_SUCCESS,
    DELETE_VPA_FAILURE
} from './vpaActions';

const initialState = {
    loading: true,
    vpa: null,
    error: null,
    vpa_details: null,
};

const vpaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VPA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_VPA_SUCCESS:
            return {
                ...state,
                loading: false,
                vpa: action.payload,
            };
        case FETCH_VPA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case UPDATE_VPA_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_VPA_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                vpa_details: action.payload,
            };
        case UPDATE_VPA_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DELETE_VPA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_VPA_SUCCESS:
            return {
                ...state,
                loading: false,
                vpa: state.vpa && state.vpa[0] && "vpa" in state.vpa[0] 
                    ? [
                        {
                            ...state.vpa[0], 
                            vpa: state.vpa[0].vpa.filter(vpa => vpa.vpaId !== action.payload)
                        },
                        ...state.vpa.slice(1)
                      ] 
                    : state.vpa,
            };

        case DELETE_VPA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CLEAR_STORE:
            return initialState; // Reset the store to the initial state
        default:
            return state;
    }
};

export default vpaReducer;
