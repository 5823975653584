import {
    FETCH_PROGRAM_REQUEST,
    FETCH_PROGRAM_SUCCESS,
    FETCH_PROGRAM_FAILURE,
    CLEAR_STORE
} from './programActions';

const initialState = {
    loading: true,
    program: null,
    error: null
};

const programReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROGRAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_PROGRAM_SUCCESS:
            return {
                ...state,
                loading: false,
                program: action.payload,
            };
        case FETCH_PROGRAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CLEAR_STORE:
            return initialState; // Reset the store to the initial state
        default:
            return state;
    }
};

export default programReducer;