import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import '../pages.scss';
import Image from 'react-bootstrap/Image';
import { IoMdArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { fetchVPAs } from "../../redux/vpaActions";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { deleteVPA } from "../../redux/vpaActions";
import PlaceholderImage from "../../assests/placeholder_card_img.jpg";
import { FiPlusCircle } from "react-icons/fi";
import { useLocation } from 'react-router-dom';





function VpaPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { vpa, loading, error } = useSelector((state) => state.vpa);

    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setSearchVisible] = useState(false); // State to manage search visibility
    const [filteredVpas, setFilteredVpas] = useState([])
    const location = useLocation();
    const { program_name, program_id, principal_name, principal_id, tenant_id, tenant_name } = location.state || {};


    useEffect(() => {
        if (vpa) {
            setFilteredVpas(vpa && vpa[0] && "vpa" in vpa[0] ? vpa[0]["vpa"]
                .filter(item =>
                    item["vpa"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item["providerName"].toLowerCase().includes(searchTerm.toLowerCase())
                )
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt
                : []);
        }
      }, [vpa]);

    useEffect(() => {
        dispatch(fetchVPAs(id));
    }, [dispatch, id]);

    const handleClick = async (vpaId) => {
        try {
            navigate('/vpa/' + id + '/' + vpaId);
        } catch (error) {
            console.error(error);
        }
    };

    const deleteVpaHandle = async (vpaId) => {
        try {
            await dispatch(deleteVPA(vpaId)); // Wait for the promise to resolve
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchChange = (e) => {
        console.log(e.target.value)
        setSearchTerm(e.target.value);
    };



    const toggleSearchVisibility = () => {
        setSearchVisible(prev => !prev);
        if (isSearchVisible) {
            setSearchTerm(""); // Clear search term when hiding
        }
    };

    const getVpaList = (vpa_list) => {
        if (vpa_list) {
            return <>
                <div className="title">
                    Showing {vpa_list.length} VPAs
                </div>
                {vpa_list.map((item, index) => (
                    <div key={index} className="list-card">
                        <div className="list-card-img"> <Image src={item["qrImage"] ? item["qrImage"] : PlaceholderImage} /></div>
                        <div className="card-body">
                            <div className="title item">{formatDate(item["createdAt"])}</div>
                            <div className="item">{item["vpa"]}</div>
                            <Button onClick={() => {
                                return deleteVpaHandle(item["vpaId"])
                            }}>Delete</Button>
                        </div>

                    </div>
                ))}
            </>
        }
        else {
            return <>
                <div className="title">
                    Showing 0 VPAs
                </div>

            </>
        }
    }


    function formatDate(input) {
        const date = new Date(input);
        const options = { month: '2-digit', day: '2-digit', year: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true };

        const formattedDate = date.toLocaleString('en-US', options).replace(',', ''); // Remove the comma
        return `ADDED: ${formattedDate}`;
    }

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            {vpa && (
                <div>
                    <div className="navbar-header">
                        <IoMdArrowBack onClick={() => navigate("/tenant/" + tenant_id, {
                            state: {
                                program_name,
                                program_id,
                                principal_name,
                                principal_id,
                                tenant_id,
                                tenant_name
                            }
                        })} />
                        {!isSearchVisible && (<div>Add VPAs  <div className="subheading">{tenant_name}</div></div>)}
                        <div className="navbar-search">
                            {/* <input
                                className={isSearchVisible ? "active" : ""}
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search"
                            />
                            <IoIosSearch
                                onClick={toggleSearchVisibility} // Toggle search visibility on icon click
                            /> */}
                        </div>

                    </div>

                    <div className="navbar-page vpa-page">
                        {getVpaList(filteredVpas)}
                    </div>


                    <div className="floating-btn">
                        <Button onClick={() => navigate(`/tenant/vpa/add/${tenant_id}`,
                            {
                                state: {
                                    program_name,
                                    program_id,
                                    principal_name,
                                    principal_id,
                                    tenant_id,
                                    tenant_name
                                }
                            }
                        )} className="floating-btn">
                            <FiPlusCircle />
                            Add VPA
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VpaPage;
